import React from "react"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Accordion, Card } from "react-bootstrap"
import ContactForm from "../components/form/form"
import { StaticImage } from "gatsby-plugin-image"
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm"
import "react-id-swiper/lib/styles/css/swiper.css"
import Swiper from "react-id-swiper"
import ContactSection from "../components/sections/contact-section"

const AltitudePackages = ({ data, slidesperview }) => {
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: slidesperview,
    spaceBetween: 30,
    containerClass: "swiper-container pb-5",
    nested: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: false,
    navigation: false,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  }
  return (
    <Layout>
      <SEO title="Altitude Package" description="Mile High Training, LLC" />
      <div className="altitude-page " style={{ backgroundColor: "#f1f1f1" }}>
        <Container className="h-100">
          <div className="row justify-content-center h-100">
            <div className="col-md-12 h-100">
              <div className="h-100 d-flex justify-content-center align-items-center">
                <div
                  className="altitude-inner text-center"
                  style={{
                    height: "auto",
                    maxWidth: "70em",
                    margin: "0 auto",
                  }}
                >
                  <div>
                    {" "}
                    <h1 className="text-white text-capitalize mb-0">
                      We offer equipment packages with all the required
                      accessories for all three altitude training methods.
                    </h1>
                    <p
                      className="lead text-white mb-5"
                      style={{ maxWidth: "880px", margin: "0 auto" }}
                    >
                      Share in our passion in helping teams and individuals
                      enhance their performance, recover faster and improve
                      their quality of life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="container" style={{ padding: "100px 0" }}>
        <h1>Equipment packages</h1>
        <div className="row">
          <div className="col-lg-4">
            <div style={{ padding: "20px" }}>
              <div
                className="shadow"
                style={{ borderRadius: "5px", overflow: "hidden" }}
              >
                <StaticImage
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../images/altitude-package-img.png"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <p className="my-3 font-weight-bold" style={{ fontSize: "22px" }}>
                Professional Teams
              </p>
              <p>
                Provide a constant stream of oxygen reduced air and train at
                real simulated altitudes.
              </p>
              <div className="mt-5">
                <a href="#" className="btn-solid">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div style={{ padding: "20px" }}>
              <div
                className="shadow"
                style={{ borderRadius: "5px", overflow: "hidden" }}
              >
                <StaticImage
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../images/altitude-package-img2.png"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <p className="my-3 font-weight-bold" style={{ fontSize: "22px" }}>
                PAltitude Tent Package
              </p>
              <p className="mb-5">
                Using a high altitude tent avoids inherent problems associated
                with permanent residence at altitude. Pre-acclimatize and
                improve your endurance.
              </p>
              <a href="#" className="btn-solid mt-auto">
                Learn More
              </a>
              <div className="mt-auto"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="h-100" style={{ padding: "20px" }}>
              <div
                className="shadow"
                style={{ borderRadius: "5px", overflow: "hidden" }}
              >
                <StaticImage
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../images/altitude-package-img3.png"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <p className="my-3 font-weight-bold" style={{ fontSize: "22px" }}>
                Altitude Canopy Package
              </p>
              <p>
                Avoid altitude training problems such as limited training load
                in oxygen deficient air, muscle loss, immune system suppression,
                and more.
              </p>
              <a href="#" className="btn-solid mt-auto">
                View Product Specification
              </a>
              <div className="mt-auto"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ padding: "100px 0" }}>
        <h1>Accessories and Individial Items</h1>
        <div className="row">
          <div className="col-lg-4">
            <div style={{ padding: "20px" }}>
              <div
                className="shadow"
                style={{ borderRadius: "5px", overflow: "hidden" }}
              >
                <StaticImage
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../images/altitude-package-img2.png"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <p className="my-3 font-weight-bold" style={{ fontSize: "22px" }}>
                Altitude Tent (Queen or King)
              </p>
              <p>$299.00</p>
              <div className="mt-5">
                <a href="#" className="btn-solid">
                  View Product Specification
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div style={{ padding: "20px" }}>
              <div
                className="shadow"
                style={{ borderRadius: "5px", overflow: "hidden" }}
              >
                <StaticImage
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../images/altitude-package-img4.png"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <p className="my-3 font-weight-bold" style={{ fontSize: "22px" }}>
                Altitude Training Mask
              </p>
              <p className="mb-5">$299.00</p>
              <a href="#" className="btn-solid mt-auto">
                View Product Specification
              </a>
              <div className="mt-auto"></div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="h-100" style={{ padding: "20px" }}>
              <div
                className="shadow"
                style={{ borderRadius: "5px", overflow: "hidden" }}
              >
                <StaticImage
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Hero BG"
                  src="../images/altitude-package-img5.png"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <p className="my-3 font-weight-bold" style={{ fontSize: "22px" }}>
                Altitude Sleep Mask
              </p>
              <p>$299.00</p>

              <div className="mt-5">
                <a href="#" className="btn-solid mt-auto">
                  View Product Specification
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bottom-rental" style={{ backgroundColor: "#f1f1f1" }}>
        <Container>
          <h1 className="text-center text-capitalize mb-0">More Options</h1>{" "}
          <Swiper {...params} slidesPerView={3}>
            <div>
              <div className="rental-hero-box4 d-flex justify-content-center align-items-center">
                <a href="#" className="btn-solid mx-2">
                  Altitude Mask Rentals
                </a>
              </div>
            </div>
            <div>
              <div className="rental-hero-box5 d-flex justify-content-center align-items-center">
                <a href="#" className="btn-solid mx-2">
                  Altitude Tent Rentals
                </a>
              </div>
            </div>
            <div>
              <div className="rental-hero-box6 d-flex justify-content-center align-items-center">
                <a href="#" className="btn-solid mx-2">
                  Chamber Rentals
                </a>
              </div>
            </div>
          </Swiper>
        </Container>
      </section>

      <div className="contact-section " style={{ backgroundColor: "#fff" }}>
        <Container className="h-100">
          <div className="row justify-content-center h-100">
            <div className="col-md-4 h-100">
              {" "}
              <h2 className="text-capitalize mt-0 mb-5">Have Any Questions?</h2>
              <p className="mb-3">Mail Us!</p>
              <a className="mb-3" href="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width={18}
                  className="mb-1 me-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                info@milehightraining.com
              </a>
              <p className="my-3">Call Us!</p>
              <a href="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  className="mb-1 me-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />{" "}
                </svg>
                +800-627-4510
              </a>
            </div>
            <div className="col-md-4 h-100">
              {" "}
              <div className="contact-inner">
                <ContactForm
                  form_name="Newsletter Form"
                  form_id="5bcd42f86b63453b251972bc"
                  form_classname="form-newsletter"
                >
                  <div className="form-row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="fullName"
                          placeholder="FullName"
                          required=""
                        />
                        {/* <label htmlFor="firstName">FullName</label> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      id="homeEmail"
                      placeholder="Email address"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="phone"
                      placeholder="Phone (optional)"
                      type="tel"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control h-auto"
                      id="message"
                      placeholder="Message"
                      rows="10"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group mt-3">
                    <button className="btn-transparent-white" type="submit">
                      Send Message
                    </button>
                  </div>
                </ContactForm>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default AltitudePackages
